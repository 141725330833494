import React from "react"
import { Container, Card, Header, Icon } from "semantic-ui-react"
import Image from "gatsby-image"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContributorsPage = ({ data }) => (
  <Layout>
    <SEO title="Contributors" />
    <Container style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          Contributors
          <Header.Subheader>
            Who is behind this collection of misconceptions?
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        This collection of programming misconceptions was created and is
        maintained by the following contributors:
      </p>
      <Card.Group className="four">
        {data.allContributorsYaml.edges.map(({ node }, index) => (
          <Card key={index} as={Link} to={node.slug}>
            {node.image ? (
              <Image
                className="ui image"
                fluid={node.image.childImageSharp.fluid}
                alt={node.name}
              />
            ) : null}
            <Card.Content>
              <Card.Header>{node.name}</Card.Header>
              <Card.Meta>
                <span className="date">{node.org}</span>
              </Card.Meta>
              <Card.Description>{node.role}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Container>
  </Layout>
)

export default ContributorsPage

export const query = graphql`
  {
    allContributorsYaml {
      edges {
        node {
          slug
          name
          org
          role
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                src
              }
            }
          }
        }
      }
    }
  }
`
